import React from 'react'
import Features from 'components/Features'
import Services from 'components/Services'

export default function OmOss() {
  return (
    <>
      <Features />
      <Services />
    </>
  )
}
