import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import {
  UncontrolledCollapse,
  NavLink,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import logo from "../assets/img/Logga SIMAB PNG.png";

export default function MainNavbar() {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("bg-info-navbar");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowBanner(true);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
              <img className="spolbil-logo" src={logo} alt="logo" />
            </NavbarBrand>
            <button className="navbar-toggler" id="navigation">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <UncontrolledCollapse
            className="main-navbar-collapse"
            navbar
            toggler="#navigation"
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img className="spolbil-logo" src={logo} alt="logo" />
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button className="navbar-toggler" id="navigation">
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <NavItem className="">
                <NavLink href="/">
                  <span className="nav-items">Hem</span>
                </NavLink>
              </NavItem>
              <NavItem className="">
                <NavLink href="/om-oss">
                  <span className="nav-items">Om Oss</span>
                </NavLink>
              </NavItem>
              <NavItem className="">
                <NavLink href="/tjanster">
                  <span className="nav-items">Tjänster</span>
                </NavLink>
              </NavItem>
              <NavItem className="">
                <NavLink href="/kontakta-oss">
                  <span className="nav-items">Kontakta oss</span>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
      {showBanner && (
        <CookieConsent
          location="bottom"
          cookieName="Spolbil Cookie"
          hideOnAccept={true}
          cookieValue={true}
          expires={30}
          style={{
            background: "#2C2C2C",
            padding: "10px 20px",
            fontSize: "16px",
          }}
          buttonText="Jag håller med"
          buttonStyle={{
            color: "#fff",
            background: "#53C226",
            fontSize: "14px",
            borderRadius: "19px",
            padding: "10px 15px",
            outline: "none",
          }}
        >
          Denna webbplats använder cookies för att förbättra
          användarupplevelsen.
        </CookieConsent>
      )}
    </>
  );
}
