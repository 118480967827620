import React from 'react'

import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
  } from "reactstrap";

export default function Services() {
  return (
    <section className="section blogs-3 services-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Services</h2>
                <Card className="card-blog card-plain blog-horizontal">
                  <Row>
                    <Col lg="4">
                      <div className="card-image">
                        <a href="/">
                          <img
                            alt="..."
                            className="img rounded"
                            src={require("assets/img/serge-kutuzov.jpg")}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col lg="8">
                      <CardBody>
                        <CardTitle tag="h3">
                          <a href="/">
                            Rover raised $65 million for pet sitting
                          </a>
                        </CardTitle>
                        <p className="card-description">
                          Finding temporary housing for your dog should be as
                          easy as renting an Airbnb. That’s the idea behind
                          Rover, which raised $65 million to expand its pet
                          sitting and dog-walking businesses..Finding temporary
                          housing for your dog should be as easy as renting an
                          Airbnb. That’s the idea behind Rover, which raised $65
                          million to expand its pet sitting and dog-walking
                          businesses..{" "}
                          <a href="/">
                            Read More
                          </a>
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-blog card-plain blog-horizontal">
                  <Row>
                    <Col lg="4">
                      <div className="card-image">
                        <a href="/">
                          <img
                            alt="..."
                            className="img rounded"
                            src={require("assets/img/trae-gould.jpg")}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col lg="8">
                      <CardBody>
                        <CardTitle tag="h3">
                          <a href="/">
                            MateLabs mixes machine learning with IFTTT
                          </a>
                        </CardTitle>
                        <p className="card-description">
                          If you’ve ever wanted to train a machine learning
                          model and integrate it with IFTTT, you now can with a
                          new offering from MateLabs. MateVerse, a platform
                          where novices can spin out machine...If you’ve ever
                          wanted to train a machine learning model and integrate
                          it with IFTTT, you now can with a new offering from
                          MateLabs. MateVerse, a platform where novices can spin
                          out machine...{" "}
                          <a href="/">
                            Read More
                          </a>
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-blog card-plain blog-horizontal">
                  <Row>
                    <Col lg="4">
                      <div className="card-image">
                        <a href="/">
                          <img
                            alt="..."
                            className="img rounded"
                            src={require("assets/img/mark-harrison.jpg")}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col lg="8">
                      <CardBody>
                        <CardTitle tag="h3">
                          <a href="/">
                            US venture investment ticks up in Q2 2017
                          </a>
                        </CardTitle>
                        <p className="card-description">
                          Venture investment in U.S. startups rose sequentially
                          in the second quarter of 2017, boosted by large,
                          late-stage financings and a few outsized early-stage
                          rounds in tech and healthcare..enture investment in
                          U.S. startups rose sequentially in the second quarter
                          of 2017, boosted by large, late-stage financings and a
                          few outsized early-stage rounds in tech and
                          healthcare..{" "}
                          <a href="/">
                            Read More
                          </a>
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
  )
}
