import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.2.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

// Components and pages
import Layout from "components/Layout";
import { Hem } from "views/Hem";
import OmOss from "views/OmOss";
import Tjanster from "views/Tjanster";
import Kontakt from "views/Kontakt";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Layout>
      <Routes>
        <Route path="/" element={<Hem />} />
        <Route path="/om-oss" element={<OmOss />} />
        <Route path="/tjanster" element={<Tjanster />} />
        <Route path="/kontakta-oss" element={<Kontakt />} />
      </Routes>
    </Layout>
  </BrowserRouter>
);
