import React from 'react'

import {
    Button,
    Container,
    Row,
    Col,
  } from "reactstrap";

export default function Header({ title, subtitle }) {
  return (
    <div className="page-header">
          <Container className='header-container'>
            <Row className="row-grid justify-content-between align-items-center text-left header-row">
              <Col lg="6" md="6">
                <h1 className="text-white">
                  {title}
                </h1>
                <p className="text-white mb-3">
                  {subtitle}
                </p>
                <div className="btn-wrapper">
                  <div className="button-container">
                    <Button
                      className="btn-icon btn-simple btn-round mr-1"
                      color="neutral"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                    <Button
                      className="btn-icon btn-simple btn-round"
                      color="neutral"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook" />
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg="4" md="5">
                <img
                  alt="..."
                  className="img-fluid header-img"
                  src={require("assets/img/etherum.png")}
                />
              </Col>
            </Row>
          </Container>
        </div>
  )
}
