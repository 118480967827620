import React from 'react'

import { Button, Container, Row, Col } from "reactstrap";

export default function Intro() {
  return (
    <section className="features features-5 intro-section">
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <div className="featured-image">
                  <img
                    alt="..."
                    height="600"
                    src={require("assets/img/pawel-nolbert.jpg")}
                    width="500"
                  />
                </div>
              </Col>
              <Col className="mt-md-5" lg="6">
                <h6 className="category">Read this first</h6>
                <h2 className="title">Company values</h2>
                <Row>
                  <Col lg="6" md="6">
                    <div className="info">
                      <h4 className="info-title">Honest</h4>
                      <p className="description intro-description">
                        Gain access to the demographics, psychographics, and
                        location of unique people.
                      </p>
                    </div>
                    <div className="info">
                      <h4 className="info-title">Efficient</h4>
                      <p className="description intro-description">
                        Gain access to the demographics, psychographics, and
                        location of unique people.
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="6">
                    <div className="info">
                      <h4 className="info-title">Helpful</h4>
                      <p className="description intro-description">
                        Gain access to the demographics, psychographics, and
                        location of unique people.
                      </p>
                    </div>
                    <div className="info">
                      <h4 className="info-title">Quality</h4>
                      <p className="description intro-description">
                        Gain access to the demographics, psychographics, and
                        location of unique people.
                      </p>
                    </div>
                  </Col>
                  <Button
                    className="btn-link ml-4 intro-btn"
                    href="/"
                    onClick={(e) => e.preventDefault()}
                  >
                    Why we are different{" "}
                    <i className="tim-icons icon-double-right" />
                  </Button>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
  )
}
