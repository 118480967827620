import React from 'react'

import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardFooter,
    Container,
    Row,
    Col,
  } from "reactstrap";

export default function Cards() {
  return (
    <section className="projects-3 cards-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center mb-5" md="8">
                <Badge color="info">Tjänster</Badge>
                <h2 className="title mt-0">Some of Our Awesome Services</h2>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="card-blog card-plain">
                  <CardHeader>
                    <h2 className="title">Habits</h2>
                    <Badge>Informations</Badge>
                  </CardHeader>
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded img-raised"
                        src={require("assets/img/card-blog2.jpg")}
                      />
                    </a>
                  </div>
                  <CardFooter className="text-left">
                    <div className="author">
                      <Button className="btn-round btn-simple cards-btn">
                        Find out more
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-blog card-plain">
                  <CardHeader>
                    <h2 className="title">Habits</h2>
                    <Badge>Informations</Badge>
                  </CardHeader>
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded img-raised"
                        src={require("assets/img/chester-wade.jpg")}
                      />
                    </a>
                  </div>
                  <CardFooter className="text-left">
                    <div className="author">
                      <Button className="btn-round btn-simple cards-btn">
                        Find out more
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-blog card-plain">
                  <CardHeader>
                    <h2 className="title">Habits</h2>
                    <Badge>Informations</Badge>
                  </CardHeader>
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded img-raised"
                        src={require("assets/img/fabien-bazanegue.jpg")}
                      />
                    </a>
                  </div>
                  <CardFooter className="text-left">
                    <div className="author">
                      <Button className="btn-round btn-simple cards-btn">
                        Find out more
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-blog card-plain">
                  <CardHeader>
                    <h2 className="title">Habits</h2>
                    <Badge>Informations</Badge>
                  </CardHeader>
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded img-raised"
                        src={require("assets/img/mark-finn.jpg")}
                      />
                    </a>
                  </div>
                  <CardFooter className="text-left">
                    <div className="author">
                      <Button className="btn-round btn-simple cards-btn">
                        Find out more
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
  )
}
