import React from 'react'
import Header from 'components/Header'
import Features from 'components/Features'
import Intro from 'components/Intro'
import Cards from 'components/Cards'

export const Hem = () => {
  return (
    <>
        <Header 
          title="Spolbil i Mälardalen - SIM AB" 
          subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        />
        <Features />
        <Intro />
        <Cards />
    </>
  )
}
