import React from 'react'

import {
    Container,
    Row,
    Col,
  } from "reactstrap";

export default function CTA() {
  return (
    <section className="contactus-3 cta-section">
          <div className="page-header header-filter">
            <div
              className="page-header-image"
              style={{
                backgroundImage:
                  "url(" + require("assets/img/andre-benz.jpg") + ")",
              }}
            />
            <Container>
              <Row>
                <Col className="text-center" md="12">
                  <h2 className="title">Got a question?</h2>
                  <h3>We'd like to talk more about what you need</h3>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row>
              <Col lg="3" md="6" className='cta-column'>
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/success.png")}
                    />
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <h4 className="info-title">Address</h4>
                  <p className="description cta-description">12124 First Street, nr 54</p>
                </div>
              </Col>
              <Col lg="3" md="6" className='cta-column'>
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/success.png")}
                    />
                    <i className="tim-icons icon-email-85" />
                  </div>
                  <h4 className="info-title">Email</h4>
                  <p className="description cta-description">support@youremail.com</p>
                </div>
              </Col>
              <Col lg="3" md="6" className='cta-column'>
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/success.png")}
                    />
                    <i className="tim-icons icon-mobile" />
                  </div>
                  <h4 className="info-title">Phone Number</h4>
                  <p className="description cta-description">+1(424) 535 3523</p>
                </div>
              </Col>
              <Col lg="3" md="6">
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <img
                      alt="..."
                      className="bg-blob"
                      src={require("assets/img/feature-blob/success.png")}
                    />
                    <i className="tim-icons icon-single-02" />
                  </div>
                  <h4 className="info-title">Contact</h4>
                  <p className="description cta-description">Andrew Samian</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
  )
}
