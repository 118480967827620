import React from "react";
import MainNavbar from "./Navbar";
import CTA from "./CTA";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <>
      <MainNavbar />
      {children}
      <CTA />
      <Footer />
    </>
  );
}
