import React from "react";

import {
  Button,
  Container,
} from "reactstrap";

export default function Footer() {
  return (
    <>
      <footer className="footer" data-background-color="black">
            <Container className="footer-container">
              <a
                className="footer-brand"
                href="/"
                onClick={(e) => e.preventDefault()}
              >
                Spolbil i Mälardalen - SIM AB
              </a>
              <ul className="pull-center">
                <li>
                  <a
                    href="/"
                    className="ml-1"
                  >
                    Hem
                  </a>
                </li>
                <li>
                  <a
                    href="/om-oss"
                    className="ml-1"
                  >
                    Om Oss
                  </a>
                </li>
                <li>
                  <a
                    href="/tjanster"
                    className="ml-1"
                  >
                    Tjänster
                  </a>
                </li>
                <li>
                  <a
                    href="/kontakta-oss"
                    className="ml-1"
                  >
                    Kontakta oss
                  </a>
                </li>
              </ul>
              <ul className="social-buttons pull-right">
                <li>
                  <Button
                    className="btn-icon btn-neutral ml-1"
                    color="link"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square" />
                  </Button>
                </li>
                <li>
                  <Button
                    className="btn-icon btn-neutral ml-1"
                    color="link"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </li>
              </ul>
            </Container>
          </footer>
    </>
  )
}
